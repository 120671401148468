/*
 * Toolbar
 *
 * Index
 * - Toolbar container
 * - Toolbar content
 * - Toolbar buttons
 * - Toolbar colors
 *
 */

.toolbar {
  position: relative;
  box-sizing: border-box;
  display: block;
  width: 100%;
  contain: content;
  color: var(--text);
  background: var(--white);
  border-bottom: 1px solid var(--border);
}

/* TOOLBAR CONTAINER
 -------------------- */

.toolbar-container {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 3.5rem;
  contain: content;
  overflow: hidden;
}

/* TOOLBAR TITLE CONTAINER
 -------------------- */

.toolbar-title-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 3.5rem;
  text-align: center;
}

/* TOOLBAR TITLE
 -------------------- */

.toolbar-title {
  display: block;
  width: 100%;
  overflow: hidden;
  font-size: 1rem;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: auto;
}

/* TOOLBAR CONTENT
 -------------------- */

.toolbar-content {
  flex: 1 1 0%;
  min-width: 0;
  max-width: 100%;
}

/* TOOLBAR BUTTONS
 -------------------- */

.toolbar .btn {
  position: relative;
  z-index: 1;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  background: var(--white);
  border-radius: 1.5rem;
}

/* TOOLBAR COLORS
 -------------------- */

:host(.toolbar-primary) {
  color: var(--primary-contrast);
  background: var(--primary);
}

:host(.toolbar-secondary) {
  color: var(--primary-contrast);
  background: var(--secondary);
}
