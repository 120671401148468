/*
 * Progress Bar
 *
 * Index
 * - Progress
 * - Progress Bar
 *
 */


/* PROGRESS
 -------------------- */

.progress {
  display: flex;
  height: 0.25rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: var(--background);
  border-radius: 0.25rem;
}

/* PROGRESS BAR
 -------------------- */

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--white);
  text-align: center;
  white-space: nowrap;
  background-color: var(--secondary);
  transition: width 0.6s ease;
}
