/*
 * Navigation bottom
 *
 * Index
 * - Nav bottom
 * - Nav bottom item
 * - Nav bottom link
 * - Nav bottom icon
 *
 */


.nav {
  display: flex;
  width: 100%;
  padding-left: 0;
  margin: 0;
  overflow-x: inherit;
  font-family: var(--font);
  line-height: 1.5;
  color: var(--text);
  list-style: none;
  background: var(--white);
  fill: currentColor;
}

/* NAV BOTTOM
 -------------------- */

.nav-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 3.5rem;
  border-top: 1px solid var(--border);
}

@media (max-width: 576px) {

  .nav-bottom {
    overflow-x: auto;
  }
}

/* NAV BOTTOM ITEM
 -------------------- */

.nav-bottom-item {
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

/* NAV BOTTOM LINK
 -------------------- */

.nav-bottom-link {
  box-sizing: border-box;
  display: block;
  font-size: 0.625rem;
  text-align: center;
  white-space: nowrap;
}

/**
 * Active state
 */

.active .nav-bottom-link {
  color: var(--secondary);
}

/* NAV BOTTOM ICON
 -------------------- */

.nav-bottom-icon {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: auto;
  margin-left: auto;
}

/**
 * Active state
 */

.active .nav-bottom-icon {
  fill: currentColor;
}
