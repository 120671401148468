/*
 * Input Group
 *
 * Easily extend form controls
 * by adding text, buttons, or button groups
 * on either side of textual inputs, custom selects, and custom file inputs.
 *
 * Index
 * - Input group
 * - Input group prepend / append
 * - Input group text or icon addon
 * - Input group round corners
 * - Input group error
 * - Input group counter
 *
 */


/* INPUT GROUP
 -------------------- */

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  margin-bottom: 1rem;
  font-family: var(--font);
}

.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control.less-label,
.input-group > .form-select.less-label {
  padding-top: 0;
}

/**
 * Focus state
 */

.input-group > .form-control:focus ~ .input-group-prepend .btn-outline-defaul,
.input-group > .form-control:focus ~ .input-group-prepend .input-group-text,
.input-group > .form-select:focus ~ .input-group-prepend .btn-outline-defaul,
.input-group > .form-select:focus ~ .input-group-prepend .input-group-text {
  border-color: var(--secondary);
  border-right-color: transparent;
}

.input-group > .form-control:focus ~ .input-group-append .btn-outline-default,
.input-group > .form-control:focus ~ .input-group-append .input-group-text,
.input-group > .form-select:focus ~ .input-group-append .btn-outline-default,
.input-group > .form-select:focus ~ .input-group-append .input-group-text {
  border-color: var(--secondary);
  border-left-color: transparent;
}

/* INPUT GROUP PREPEND - APPEND
 -------------------- */

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
  min-width: 3rem;
  padding: 0 0.5rem;
  margin: 0;
  font-size: 0.875rem;
}

.counter .btn {
  font-size: 1.125rem;
}

.counter .btn-md {
  min-width: 2.5rem;
}

.counter .btn-sm {
  min-width: 2rem;
}

.input-group-prepend {
  z-index: 1;
  order: -1;
  margin-right: -1px;
}

.input-group-prepend ~ .form-label {
  left: calc(3rem + 1px);
}

.input-group-append {
  position: relative;
  z-index: 1;
  margin-left: -0.25rem;
  background: var(--white);
  border-radius: 0 0.25rem 0.25rem 0;
}

.input-icon {
  display: block;
  width: 2rem;
  height: 2rem;
  fill: currentColor;
}

/* INPUT GROUP TEXT OR ICON ADDON
 -------------------- */

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  cursor: text;
  background: var(--white);
  border: 1px solid var(--border-form);
  border-radius: 0.25rem;
}

/* INPUT GROUP ROUND CORNERS
 -------------------- */

.input-group > .form-control:not(:first-child),
.input-group > .form-select:not(:first-child) {
  border-radius: 0;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .input-group-prepend > .input-group-text {
  margin-right: calc(-1rem + 1px);
}

/* INPUT GROUP ERROR
 -------------------- */

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: var(--danger-text);
}

.form-control.is-invalid,
.was-validated .form-control:invalid,
.form-select.is-invalid,
.was-validated .form-select:invalid {
  border-color: var(--danger);
}

.form-control.is-invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-feedback,
.form-select.is-invalid ~ .invalid-feedback,
.was-validated .form-select:invalid ~ .invalid-feedback {
  display: block;
}

/**
 * Prepend error
 */

.form-control.is-invalid ~ .input-group-prepend .btn-outline-defaul,
.form-control.is-invalid ~ .input-group-prepend .input-group-text,
.was-validated .form-control:invalid ~ .input-group-prepend .btn-outline-defaul,
.was-validated .form-control:invalid ~ .input-group-prepend .input-group-text,
.form-select.is-invalid ~ .input-group-prepend .btn-outline-defaul,
.form-select.is-invalid ~ .input-group-prepend .input-group-text,
.was-validated .form-select:invalid ~ .input-group-prepend .btn-outline-defaul,
.was-validated .form-select:invalid ~ .input-group-prepend .input-group-text {
  border-color: var(--danger);
  border-right-color: transparent;
}

/**
 * Append error
 */

.form-control.is-invalid ~ .input-group-append .btn-outline-default,
.form-control.is-invalid ~ .input-group-append .input-group-text,
.was-validated .form-control:invalid ~ .input-group-append .btn-outline-default,
.was-validated .form-control:invalid ~ .input-group-append .input-group-text,
.form-select.is-invalid ~ .input-group-append .btn-outline-default,
.form-select.is-invalid ~ .input-group-append .input-group-text,
.was-validated .form-select:invalid ~ .input-group-append .btn-outline-default,
.was-validated .form-select:invalid ~ .input-group-append .input-group-text {
  border-color: var(--danger);
  border-left-color: transparent;
}

/**
 * Remove border for append and prepend element
 * with white background
 */

.input-group .input-group-prepend > .btn-outline-default,
.input-group .input-group-prepend > .input-group-text {
  border-color: var(--border-form);
  border-right-color: transparent;
}

.input-group .input-group-append > .btn-outline-default,
.input-group .input-group-append > .input-group-text {
  border-color: var(--border-form);
  border-left-width: 0;
}

/* INPUT GROUP COUNTER
 -------------------- */

.btn-number {
  border: 1px solid var(--border-form);
}

/**
 * Focus state
 */

.input-group > .input-number:focus {
  z-index: 2;
}
