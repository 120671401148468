/*
 * Checkbox
 *
 * For accessibility, we provide a style for focus only on Tab,
 * for that add the javascript polyfill for focus-visible
 * (https://github.com/WICG/focus-visible).
 *
 * Index
 * - Checkbox
 * - Checkbox label
 * - Checkbox error
 *
 */


/* CHECKBOX
 -------------------- */

.form-check {
  position: relative;
  display: block;
  margin-bottom: 1.5rem;
  font-family: var(--font);
  font-size: 0.875rem;
  line-height: 1.5;
  color: var(--text);
}

/**
 * Hide input
 */

.form-check-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

/* CHECKBOX LABEL
 -------------------- */

.form-check-label {
  position: relative;
  display: inline-flex;
  margin: 0;
  cursor: pointer;
}

.form-check-label::before {
  box-sizing: border-box;
  display: block;
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin: 0 1rem 0 0;
  content: "";
  background: var(--white);
  border: 1px solid var(--border-form);
  border-radius: 0.25rem;
}

.form-check-label::after {
  position: absolute;
  top: 0.125rem;
  left: 0.125rem;
  box-sizing: border-box;
  display: block;
  width: 0.375rem;
  height: 0.75rem;
  content: "";
  border-top: 0.0625rem solid transparent;
  border-right: 0.0625rem solid var(--secondary-contrast);
  border-bottom: 0.0625rem solid var(--secondary-contrast);
  border-left: 0.0625rem solid transparent;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  transform: rotateZ(45deg);
  transform-origin: 100% 100%;
}

/**
 * Hover state
 */

@media (hover: hover) {

  .form-check-input:not(:checked):not(:disabled) ~ .form-check-label:hover::after {
    border-right: 0.0625rem solid var(--border-form);
    border-bottom: 0.0625rem solid var(--border-form);
    opacity: 1;
  }

  .form-check-input:not(:checked) ~ .form-check-label:hover::before {
    border-color: var(--secondary);
  }
}

/**
 * Checked state
 */

.form-check-input:checked ~ .form-check-label::after {
  opacity: 1;
}

.form-check-input:checked ~ .form-check-label::before {
  background: var(--secondary);
  border-color: var(--secondary);
}

/**
 * Focus on Tab
 */

.form-check-input.focus-visible ~ .form-check-label::before {
  box-shadow: 0 0 0 0.125rem var(--white), 0 0 0 0.1875rem var(--secondary);
}

/**
 * Disabled state
 */

.form-check-input:disabled ~ .form-check-label,
.form-check-input[readonly] ~ .form-check-label {
  color: var(--text-disabled);
  cursor: not-allowed;
}

.form-check-input:disabled ~ .form-check-label::before,
.form-check-input[readonly] ~ .form-check-label::before {
  background: var(--background-second);
  border: none;
}

/* CHECKBOX ERROR
 -------------------- */

.invalid-feedback {
  display: none;
  flex: 0 0 100%;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: var(--danger-text);
}

.form-check-input.is-invalid ~ .form-check-label::before,
.was-validated .form-check-input:invalid ~ .form-check-label::before {
  border-color: var(--danger);
}

.form-check-input.is-invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-feedback {
  display: block;
}

/* POSITION
 -------------------- */

.form-checkbox-inverse {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.form-checkbox-inverse::before {
  margin: 0 0 0 1rem;
}

.form-checkbox-inverse::after {
  right: 0.75rem;
  left: auto;
}
