/*
 * Dropdown
 *
 * Index
 * - Dropdown
 * - Dropdown menu
 * - Dropdown item
 */

/* DROPDOWN
 -------------------- */

.dropdown {
  position: relative;
  font-family: var(--font);
}

.dropdown summary {
  display: block;
  outline: none;
}

.dropdown summary::-webkit-details-marker { /* remove native arrow */
  display: none;
}

/**
 * Open state
 */

.dropdown[open] .dropdown-menu {
  transform: scaleY(1);
}

/* MENU
 -------------------- */

.dropdown-menu {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  min-width: 16.875rem;
  padding: 0.5rem 0;
  margin-top: 0.5rem;
  color: var(--text);
  background: var(--white);
  border: 1px solid var(--border);
  border-radius: 0.25rem;
  box-shadow: 0 0.375rem 0.375rem rgba(0, 0, 0, 0.16);
  transition: transform 0.2s ease-in-out;
  transform: scaleY(0);
  transform-origin: 0 0;
}

/* ITEM
 -------------------- */

.dropdown-item {
  padding: 1rem;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--text);
  text-align: left;
  text-decoration: none;
  cursor: pointer;
  background: var(--white);
  border: none;
  outline: none;
}

/**
 * Hover state
 */

.dropdown-item:hover,
.dropdown-item.focus-visible {
  background: var(--background-second);
}
