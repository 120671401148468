/*
 * List
 *
 * For accessibility, we provide a style for focus only on Tab,
 * for that add the javascript polyfill for focus-visible
 * (https://github.com/WICG/focus-visible).
 *
 * Index
 * - List group
 * - List group segmentation title
 * - List group item action
 * - List group item
 * - List group label
 * - List group button
 * - List multiple choice
 *
 */


/* LIST GROUP
 -------------------- */

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  font-family: var(--font);
  font-size: 0.875rem;
  line-height: 1.5;
}

/* LIST GROUP SEGMENTATION TITLE
 -------------------- */

.list-group-title {
  box-sizing: border-box;
  min-height: 2rem;
  padding: 0.5rem 1rem;
  margin: 0;
  font-size: 0.75rem;
  font-weight: 700;
  color: var(--white);
  text-transform: uppercase;
  background: var(--text);
}

.list-group-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

/* LIST GROUP ITEM ACTION
 -------------------- */

.list-group-item-action {
  width: 100%;
  padding: 0.5rem 3rem 0.5rem 1rem;
  text-align: inherit;
  text-decoration: none;
  outline: 0;
}

.list-group-item-arrow {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NCA2NCI+PHBhdGggZD0iTTI0LjU5NCA0Mi4xMTJsOS4xODctOS4xODgtOS4xODctOS4xODcgMi44MTItMi44MTMgMTIgMTItMTIgMTJ6IiBmaWxsPSIjMTcxRTM0IiBmaWxsLW9wYWNpdHk9IjAuNzIiLz48L3N2Zz4=");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 2rem;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
}

/* LIST GROUP ITEM
 -------------------- */

.list-group-item {
  position: relative;
  display: flex;
  align-items: center;
  height: 4rem;
  color: var(--text);
  background-color: var(--white);
  border: 1px solid var(--border);
  transition: background 0.15s ease-in-out;
}

/**
 * First and last item
 */

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

/**
 * Active state
 */

.list-group-item.active {
  color: var(--secondary);
}

/**
 * Hover state
 */

.list-group-item:hover {
  background-color: var(--background-second);
}

/**
 * Handle shared borders
 */

.list-group-item + .list-group-item {
  border-top-width: 0;
}


/* LIST GROUP LABEL
 -------------------- */

.list-group-label {
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  min-height: 4rem;
  padding-right: 1rem;
  padding-left: 1.5rem;
  cursor: pointer;
}

/**
 * Gray circle placeholder
 */

.list-group-label::before {
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
  content: "";
  background: var(--background);
  border-radius: 50%;
  transition: background 0.15s ease-in-out;
}

/**
 * Check icon
 */

.list-group-label .list-icon {
  position: absolute;
  top: 50%;
  left: 1.75rem;
  width: 2rem;
  transition: background 0.15s ease-in-out, transform 0.2s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  transform: translateY(-50%) scale(0);
  fill: var(--white);
}

/**
 * Checked state
 */

.list-group-input:checked + .list-group-label {
  font-weight: 700;
}

.list-group-input:checked + .list-group-label::before {
  background: var(--secondary);
}

.list-group-input:checked + .list-group-label .list-icon {
  transform: translateY(-50%) scale(1);
}

/**
 * Hover state
 */

.list-group-label:hover::before {
  background: var(--white);
}

/**
 * Focus on Tab
 */

.list-group-input.focus-visible + .list-group-label {
  z-index: 1;
  border-color: var(--secondary);
}

/* LIST MULTIPLE CHOICE
 -------------------- */

.list-group-inverse .list-group-label:hover::before {
  background: var(--white);
}

.list-group-inverse .list-group-label:hover .list-icon {
  fill: var(--success);
}

.list-group-inverse .list-group-input:checked + .list-group-label {
  background: var(--background-second);
}

.list-group-inverse .list-group-input:checked + .list-group-label::before {
  background: var(--white);
}

.list-group-inverse .list-group-input:checked + .list-group-label .list-icon {
  fill: var(--success);
}
