/*
 * Switch
 *
 * For accessibility, we provide a style for focus only on Tab,
 * for that add the javascript polyfill for focus-visible
 * (https://github.com/WICG/focus-visible).
 *
 * Index
 * - Switch
 * - Switch label
 * - Switch lever
 * - Switch feedback
 * - Switch position
 *
 */


/* SWITCH
 -------------------- */

.form-switch {
  position: relative;
  display: block;
  margin-bottom: 1rem;
  font-family: var(--font);
  font-size: 0.875rem;
  color: var(--text);
}

/**
 * Hide input
 */

.form-switch-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

/* SWITCH LABEL
 -------------------- */

.form-switch-label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

/* SWITCH LEVER
 -------------------- */

.form-switch-lever {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  width: 4.5rem;
  height: 2rem;
  padding: 0.5rem 0.625rem;
  margin-right: 0.5rem;
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 1.6;
  content: "";
  background: var(--border);
  border: 0.125rem solid transparent;
  border-radius: 1.125rem;
  transition: background 0.2s ease-in-out;
}

/**
 * Text in the switch widget
 */

.form-switch-lever::before {
  font-size: 0.625rem;
  font-weight: 700;
  color: var(--white);
  text-transform: uppercase;
  content: attr(data-off);
}

/**
 * Round lever in the widget
 */

.form-switch-lever::after {
  position: absolute;
  top: 0.0625rem;
  left: 0.0625rem;
  display: block;
  width: 1.625rem;
  height: 1.625rem;
  content: "";
  background: var(--white);
  border-radius: 50%;
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

/**
 * Checked state
 */

.form-switch-input:checked ~ .form-switch-label .form-switch-lever {
  justify-content: flex-start;
  background: var(--secondary);
}

.form-switch-input:checked ~ .form-switch-label .form-switch-lever::before {
  color: var(--secondary-contrast);
  content: attr(data-on);
}

.form-switch-input:checked ~ .form-switch-label .form-switch-lever::after {
  transform: translateX(2.5rem);
}

/**
 * Focus on Tab
 */

.form-switch-input.focus-visible ~ .form-switch-label .form-switch-lever {
  box-shadow: 0 0 0 0.125rem var(--white), 0 0 0 0.1875rem var(--secondary);
}

/* SWITCH FEEDBACK
 -------------------- */

/**
 * Information
 */

.form-switch-info .form-switch-input:checked ~ .form-switch-label .form-switch-lever {
  background: var(--info);
}

.form-switch-info .form-switch-input:checked ~ .form-switch-label .form-switch-lever::before {
  color: var(--info-contrast);
}

/**
 * Success
 */

.form-switch-success .form-switch-input:checked ~ .form-switch-label .form-switch-lever {
  background: var(--success);
}

.form-switch-success .form-switch-input:checked ~ .form-switch-label .form-switch-lever::before {
  color: var(--success-contrast);
}

/**
 * Warning
 */

.form-switch-warning .form-switch-input:checked ~ .form-switch-label .form-switch-lever {
  background: var(--warning);
}

.form-switch-warning .form-switch-input:checked ~ .form-switch-label .form-switch-lever::before {
  color: var(--warning-contrast);
}

/**
 * Danger
 */

.form-switch-danger .form-switch-input:checked ~ .form-switch-label .form-switch-lever {
  background: var(--danger);
}

.form-switch-danger .form-switch-input:checked ~ .form-switch-label .form-switch-lever::before {
  color: var(--danger-contrast);
}

/* SWITCH POSITION
 -------------------- */

.form-switch-right .form-switch-lever {
  margin-right: 0;
  margin-left: 0.5rem;
}

.form-switch-spaced {
  display: flex;
  justify-content: space-between;
}
