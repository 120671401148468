/*
 * Table
 */


.table {
  width: 100%;
  font-family: var(--font);
  font-size: 0.875rem;
  line-height: 1.5;
  color: var(--text);
  border-collapse: collapse;
  border-top: 1px solid var(--border);
  border-bottom: 1px solid var(--border);
}

.table th,
.table td {
  padding: 1.5rem 1.25rem;
  text-align: left;
}

.table thead tr,
.table tbody tr:nth-child(2n) {
  background: var(--background-second);
}
