/*
 * Skeleton Screen
 *
 * Index
 * - Skeleton
 * - Skeleton animation
 *
 */


/* SKELETON
 -------------------- */

.skeleton {
  position: relative;
  display: block;
  width: 100%;
  min-height: 1rem;
  background: linear-gradient(to right, var(--background-second) 8%, var(--background) 18%, var(--background-second) 33%);
  background-size: 800px 104px;
  animation: road-skeleton-animation 1s linear infinite;
}

/* SKELETON ANIMATION
 -------------------- */

@keyframes road-skeleton-animation {

  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
