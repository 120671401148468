/*
 * Icons
 *
 * Index
 * - Sizes
 * - Colors
 * - Rotation
 */

/* SIZES
 -------------------- */

.icon-lg {
  width: 2rem;
  height: 2rem;
}

.icon-md {
  width: 1.5rem;
  height: 1.5rem;
}

.icon-sm {
  width: 1.25rem;
  height: 1.25rem;
}

.icon-3x {
  width: 3rem;
  height: 3rem;
}

.icon-4x {
  width: 4rem;
  height: 4rem;
}

/* COLORS
 -------------------- */

.icon-gray {
  fill: var(--icon);
}

.icon-secondary {
  fill: var(--secondary);
}

.icon-white {
  fill: var(--white);
}

/* ROTATION
 -------------------- */

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-270 {
  transform: rotate(270deg);
}
