/*
 * Radio
 *
 * For accessibility, we provide a style for focus only on Tab,
 * for that add the javascript polyfill for focus-visible
 * (https://github.com/WICG/focus-visible).
 *
 * Index
 * - Radio
 * - Radio label
 * - Radio error
 * - Position
 *
 */


.form-group {
  position: relative;
  display: block;
  margin-bottom: 1rem;
  font-family: var(--font);
  color: var(--text);
}

/* RADIO
 -------------------- */

.form-radio {
  position: relative;
  display: block;
  margin-bottom: 1.5rem;
  font-family: var(--font);
  font-size: 0.875rem;
  line-height: 1.5;
  color: var(--text);
}

/**
 * Inline radio
 */

.form-radio-inline {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: 1.5rem;
}

/**
 * Hide input
 */

.form-radio-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

/* RADIO LABEL
 -------------------- */

.form-radio-label {
  position: relative;
  display: inline-flex;
  margin: 0;
  cursor: pointer;
}

.form-radio-label::before {
  box-sizing: border-box;
  display: block;
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin: 0 1rem 0 0;
  content: "";
  background: var(--white);
  border: 1px solid var(--border-form);
  border-radius: 50%;
}

.form-radio-label::after {
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  box-sizing: border-box;
  display: block;
  width: 0.875rem;
  height: 0.875rem;
  content: "";
  background: var(--border-form);
  border: none;
  border-radius: 50%;
  transition: background 0.2s ease-in-out, transform 0.2s ease-in-out;
  transform: scale(0);
  transform-origin: 50% 50%;
}

/**
 * Hover state
 */

@media (hover: hover) {

  .form-radio-input:not(:checked):not(:disabled) ~ .form-radio-label:hover::after {
    opacity: 1;
    transform: scale(0.42);
  }

  .form-radio-input:not(:checked) ~ .form-radio-label:hover::before {
    border-color: var(--secondary);
  }
}

/**
 * Checked state
 */

.form-radio-input:checked ~ .form-radio-label::after {
  background: var(--secondary);
  transform: scale(1);
}

/**
 * Focus on Tab
 */

.form-radio-input.focus-visible ~ .form-radio-label::before {
  box-shadow: 0 0 0 0.125rem var(--white), 0 0 0 0.1875rem var(--secondary);
}

/**
 * Disabled state
 */

.form-radio-input:disabled ~ .form-radio-label,
.form-radio-input[readonly] ~ .form-radio-label {
  color: var(--text-disabled);
  cursor: not-allowed;
}

.form-radio-input:disabled ~ .form-radio-label::before,
.form-radio-input[readonly] ~ .form-radio-label::before {
  background: var(--background-second);
  border: none;
}

/* RADIO ERROR
 -------------------- */

.invalid-feedback {
  display: none;
  flex: 0 0 100%;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: var(--danger-text);
}

.form-radio-input.is-invalid ~ .form-radio-label::before,
.was-validated .form-radio-input:invalid ~ .form-radio-label::before {
  border-color: var(--danger);
}

.form-radio-input.is-invalid ~ .invalid-feedback,
.was-validated .form-radio-input:invalid ~ .invalid-feedback {
  display: block;
}

/**
 * Error message for inline radio can only
 * be provided by adding a class
 * .is-invalid on the .form-group of this radio
 */

.form-group .form-radio-inline {
  margin-bottom: 0;
}

.form-group.is-invalid .form-radio-label::before {
  border-color: var(--danger);
}

.form-group.is-invalid .invalid-feedback {
  display: block;
}

/* POSITION
 -------------------- */

.form-radio-inverse {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.form-radio-inverse::before {
  margin: 0 0 0 1rem;
}

.form-radio-inverse::after {
  right: 0.1875rem;
  left: auto;
}
