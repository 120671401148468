/*
 * Colors utitlities
 *
 * Index
 * - Text colors
 * - Backgriund colors
 * - Icon colors
 *
 */


/* TEXT COLORS
 -------------------- */

.text-primary {
  color: var(--primary);
}

.text-secondary {
  color: var(--secondary);
}

.text-success {
  color: var(--success);
}

.text-warning {
  color: var(--warning);
}

.text-danger {
  color: var(--danger);
}

.text-gray {
  color: var(--text);
}

.text-gray-second {
  color: var(--text-second);
}

.text-disabled {
  color: var(--text-disabled);
}

.text-white {
  color: var(--white);
}

/* BACKGROUND COLORS
 -------------------- */

.bg-primary {
  background-color: var(--primary);
}

.bg-secondary {
  background-color: var(--secondary);
}

.bg-success {
  background-color: var(--success-text);
}

.bg-warning {
  background-color: var(--warning);
}

.bg-danger {
  background-color: var(--danger);
}

.bg-light {
  background-color: var(--background);
}

.bg-white {
  background-color: var(--white);
}
