/*
 * Badge
 *
 * Index
 * - Badge
 * - Badge position
 * - Badge feedback
 *
 */


/* BADGE
 -------------------- */

.badge {
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 1rem;
  min-height: 1rem;
  padding: 0 0.25rem;
  font-family: var(--font);
  font-size: 0.625rem;
  font-weight: 700;
  color: var(--secondary-contrast);
  white-space: nowrap;
  background-color: var(--secondary);
  border-radius: 1rem;
}

/* BADGE POSITION
 -------------------- */

/**
 * Top badge
 */

.badge-sup {
  margin-left: -0.5rem;
}

/**
 * Bottom badge
 */

.badge-sub {
  bottom: -1.175rem;
  margin-left: -0.75rem;
}

/**
 * Empty badge
 */

.badge:empty {
  min-width: 0.5rem;
  min-height: 0.5rem;
}

.badge-sup .badge:empty {
  margin-top: 0.5rem;
  margin-left: -0.25rem;
}

/* BADGE FEEDBACK
 -------------------- */

/**
 * Badge primary
 */

.badge-primary {
  color: var(--primary-contrast);
  background-color: var(--primary);
}

/**
 * Badge accent
 */

.badge-accent {
  color: var(--accent-contrast);
  background-color: var(--accent);
}

/**
 * Badge info
 */

.badge-info {
  color: var(--info-contrast);
  background-color: var(--info);
}

/**
 * Badge success
 */

.badge-success {
  color: var(--success-contrast);
  background-color: var(--success);
}

/**
 * Badge warning
 */

.badge-warning {
  color: var(--warning-contrast);
  background-color: var(--warning);
}

/**
 * Badge danger
 */

.badge-danger {
  color: var(--danger-contrast);
  background-color: var(--danger-text);
}
