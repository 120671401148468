/*
 * Toast
 *
 * Index
 * - Toast
 * - Toast feedback
 * - Toast icon
 * - Toast close
 *
 */


.toast-container {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: block;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, visibility 0.15s, transform 0.3s ease-in-out;
  transform: translateY(100%);
}

/**
 * Top position
 */

.toast-top {
  top: 0;
  transform: translateY(-100%);
}

/**
 * Open state
 */

.toast-open {
  visibility: visible;
  opacity: 1;
  transform: none;
}

/* TOAST
 -------------------- */

.toast {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 100%;
  align-items: center;
  max-width: 100%;
  padding: 1.5rem 2rem 1.5rem 3rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  font-family: Muli, Arial, sans-serif;
  font-size: 0.875rem;
  line-height: 1.4;
  color: var(--info-contrast);
  text-align: left;
  background: var(--info);
  border-radius: 0.25rem 0.25rem 0 0;
}

.toast-top .toast {
  border-radius: 0 0 0.25rem 0.25rem;
}

@media (min-width: 576px) {

  .toast {
    flex: 0 0 83.3333%;
    justify-content: center;
    max-width: 83.3333%;
    padding: 1rem 2rem;
    margin-right: auto;
    margin-left: auto;
  }
}

/* TOAST FEEDBACK
 -------------------- */

/**
 * Success
 */

.toast-success {
  color: var(--success-contrast);
  background: var(--success);
}

/**
 * Warning
 */

.toast-warning {
  color: var(--warning-contrast);
  background: var(--warning);
}

/**
 * Danger
 */

.toast-danger {
  color: var(--danger-contrast);
  background: var(--danger);
}

/* TOAST ICON
 -------------------- */

.toast-icon {
  position: absolute;
  top: 1rem;
  left: 0.5rem;
  width: 2rem;
  height: auto;
  fill: var(--white);
}

@media (min-width: 576px) {

  .toast-icon {
    position: relative;
    top: auto;
    left: auto;
    margin-right: 0.5rem;
  }
}

/* TOAST CLOSE
 -------------------- */

.toast-close {
  position: absolute;
  top: 4px;
  right: 4px;
  display: inline-block;
  padding: 0;
  color: inherit;
  cursor: pointer;
  background: none;
  border: 1px solid transparent;
  border-radius: 4px;
  fill: var(--white);
}

.toast-close:focus {
  outline: none;
}

.toast-close-icon {
  display: block;
  width: 24px;
}
