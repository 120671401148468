/**
 * Typography
 *
 * Typography can help create clear hierarchies,
 * organize information, and guide users through a product or experience.
 *
 * We using Muli as the default font (https://github.com/vernnobile/MuliFont)
 *
 * Index
 * - Headings
 * - Content
 *
 */


/* HEADINGS
 -------------------- */

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.h7,
.h8,
.h9 {
  padding: 0;
  font-weight: 700;
  line-height: 1.2;
  color: var(--text);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.h7,
.h8,
.h9,
.text-content,
.text-small,
.text-legal {
  margin: 0 0 1rem;
  font-family: var(--font, sans-serif);
}

.h1 {
  font-size: 3rem;
}

.h2 {
  font-size: 2.375rem;
}

.h3 {
  font-size: 2rem;
}

.h4 {
  font-size: 1.75rem;
}

.h5 {
  font-size: 1.5rem;
}

.h6 {
  font-size: 1.25rem;
}

.h7 {
  font-size: 1.125rem;
}

.h8 {
  font-size: 1rem;
}

.h9 {
  font-size: 0.75rem;
  text-transform: uppercase;
}

/* CONTENT
 -------------------- */

.text-content,
.text-small,
.text-legal {
  line-height: 1.5;
}

.text-content {
  font-size: 0.875rem;
  color: var(--text);
}

.text-small {
  font-size: 0.75rem;
  color: var(--text-second);
}

.text-legal {
  font-size: 0.625rem;
  color: var(--text-disabled);
}
