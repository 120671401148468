/*
 * Display utilities
 *
 */

.d-none {
  display: none;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

@media (min-width: 576px) {

  .d-sm-none {
    display: none;
  }

  .d-sm-inline {
    display: inline;
  }

  .d-sm-inline-block {
    display: inline-block;
  }

  .d-sm-block {
    display: block;
  }

  .d-sm-flex {
    display: flex;
  }

  .d-sm-inline-flex {
    display: inline-flex;
  }
}

@media (min-width: 768px) {

  .d-md-none {
    display: none;
  }

  .d-md-inline {
    display: inline;
  }

  .d-md-inline-block {
    display: inline-block;
  }

  .d-md-block {
    display: block;
  }

  .d-md-flex {
    display: flex;
  }

  .d-md-inline-flex {
    display: inline-flex;
  }
}

@media (min-width: 992px) {

  .d-lg-none {
    display: none;
  }

  .d-lg-inline {
    display: inline;
  }

  .d-lg-inline-block {
    display: inline-block;
  }

  .d-lg-block {
    display: block;
  }

  .d-lg-flex {
    display: flex;
  }

  .d-lg-inline-flex {
    display: inline-flex;
  }
}

@media (min-width: 1200px) {

  .d-xl-none {
    display: none;
  }

  .d-xl-inline {
    display: inline;
  }

  .d-xl-inline-block {
    display: inline-block;
  }

  .d-xl-block {
    display: block;
  }

  .d-xl-flex {
    display: flex;
  }

  .d-xl-inline-flex {
    display: inline-flex;
  }
}
