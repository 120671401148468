/*
 * Flap
 *
 * Index
 * - Flap
 * - Colors
 */

/* FLAP
 -------------------- */

:root {
  --flap-promo: #ea0000;
  --flap-exclu: #eca000;
  --flap-info: #002b6f;
  --flap-ecology: #00ab6d;
}

.flap {
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  min-width: 3.5rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  font-weight: 700;
  border: 1px solid currentColor;
  border-radius: 1.5625rem;
}

/* COLORS
 -------------------- */

.flap-promo {
  color: var(--flap-promo);
}

.flap-exclu {
  color: var(--flap-exclu);
}

.flap-info {
  color: var(--flap-info);
}

.flap-ecology {
  color: var(--flap-ecology);
}
