/*
 * Chip
 *
 * Index
 * - Outline
 * - Icon
 * - Close
 * - Size
 * - Color
 */

.chip {
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  height: 1.625rem;
  padding: 0.25rem 0.75rem;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  cursor: pointer;
  border: 0;
  border-radius: 1.625rem;
  outline: 0;
}

/* OUTLINE
 -------------------- */

.chip-outline {
  border-style: solid;
  border-width: 1px;
}

/* ICON
 -------------------- */

.chip-icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: -0.375rem;
  fill: var(--icon);
}

/* CLOSE
 -------------------- */

.chip-close {
  width: 1rem;
  height: 1rem;
  margin-right: -0.375rem;
  margin-left: 0.375rem;
  background: var(--background);
  border-radius: 50%;
}

/* SIZE
 -------------------- */

.chip-lg {
  height: 2.25rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

/* COLOR
 -------------------- */

.chip-default {
  color: var(--text);
  background: var(--background);
}

.chip-secondary {
  color: var(--secondary-contrast);
  background: var(--secondary);
}

@media (any-hover: hover) {

  .chip-secondary:hover,
  .chip-secondary.focus-visible {
    background: var(--secondary-hover);
  }
}

.chip-secondary .chip-icon {
  fill: var(--secondary-contrast);
}

.chip-outline.chip-default {
  background: var(--white);
  border-color: var(--border);
}

.chip-outline.chip-secondary {
  color: var(--secondary);
  background: var(--white);
  border-color: var(--secondary);
}

.chip-outline.chip-secondary .chip-icon {
  fill: var(--secondary);
}
