/*
 * Checkbox
 *
 *
 * Index
 * - Input
 * - Value
 * - Slider
 * - Progress
 * - Cursor
 *
 */

:root {
  --min: 0;
  --max: 10;
  --value: 0;
}

.form-range {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 6px;
}

/* INPUT
 -------------------- */

.form-range-input {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 0.5rem;
  background: transparent;
  outline: 0;
  appearance: none;
}

/* VALUE
 -------------------- */

.form-range > output::after {
  display: block;
  font-size: 0.875rem;
  text-align: right;
  content: counter(variable);
  counter-reset: variable var(--value);
}

/* SLIDER
 -------------------- */

.form-range-progress {
  position: relative;
  z-index: 0;
  display: block;
  width: 100%;
  margin-top: -0.875rem;
}

.form-range-input::-webkit-slider-runnable-track {
  height: 0.5rem;
  border: 0;
  appearance: none;
}

.form-range-input::-moz-range-track {
  height: 0.5rem;
  border: 0;
  appearance: none;
}

.form-range-progress::before {
  display: block;
  height: 0.5rem;
  content: "";
  background-color: var(--background);
  border: 0;
  border-radius: 1.125rem;
}

/* PROGRESS
 -------------------- */

.form-range-progress::after {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: block;
  width: calc((var(--value) * 100%) / var(--max));
  height: 0.5rem;
  content: "";
  background: var(--secondary);
  border-radius: 1.125rem;
}

/* CURSOR
-------------------- */

.form-range-input::-webkit-slider-thumb {
  position: relative;
  z-index: 1;
  width: 1.625rem;
  height: 1.625rem;
  margin-top: -0.625rem;
  cursor: grab;
  background: var(--white);
  border: none;
  border-radius: 1.625rem;
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.24);
  appearance: none;
}

.form-range-input::-moz-range-thumb {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  width: 1.625rem;
  height: 1.625rem;
  margin-top: -0.625rem;
  cursor: grab;
  background: var(--white);
  border: none;
  border-radius: 1.625rem;
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.24);
  appearance: none;
}

/**
 *  Focus state
 */

.form-range-input.focus-visible::-webkit-slider-thumb,
.form-range-input:active::-webkit-slider-thumb {
  background: var(--secondary);
  border: 0.5rem solid var(--white);
}

.form-range-input.focus-visible::-moz-range-thumb,
.form-range-input:active::-moz-range-thumb {
  background: var(--secondary);
  border: 0.5rem solid var(--white);
}
