/*
 * Card
 *
 */

.card {
  box-sizing: border-box;
  width: 100%;
  padding: 1.5rem;
  margin: 0 0 1rem;
  cursor: pointer;
  user-select: none;
  background: var(--white);
  border: 0;
  border-radius: 0.25rem;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-drag: none;
}
