/*
 * Tab
 *
 * Index
 * - Tab
 * - Tab item
 * - Tab link
 * - Tab icon
 * - Active state
 *
 */


.nav {
  display: flex;
  width: 100%;
  padding-left: 0;
  margin: 0;
  overflow-x: inherit;
  font-family: var(--font);
  line-height: 1.5;
  color: var(--text);
  list-style: none;
  background: var(--white);
  fill: currentColor;
}

/* TAB
 -------------------- */

.nav-tabs {
  padding: 0 0.5rem;
  text-transform: uppercase;
  border-bottom: 1px solid var(--border);
}

/* TAB ITEM
 -------------------- */

.nav-item {
  position: relative;
  box-sizing: border-box;
  height: 3.5rem;
}

.nav-item::after {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  content: "";
  opacity: 0;
  transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  transform: scaleX(0);
  transform-origin: center;
}

/**
 * Active state
 */

.nav-item.active::after {
  background: var(--secondary);
  opacity: 1;
  transform: none;
}

/* TAB LINK
 -------------------- */

.nav-link {
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  height: 3.5rem;
  padding: 0.75rem 1.5rem;
  font-weight: 700;
  color: var(--text-second);
  text-align: center;
  white-space: nowrap;
}

/**
 * Active state
 */

.active .nav-link {
  color: var(--text);
}

/* TAB ICON
 -------------------- */

.nav-icon {
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
  fill: var(--text-second);
}

/**
 * Active state
 */

.active .nav-icon {
  fill: var(--icon);
}
