/*
 * Dialog
 *
 * Index
 * - Overlay
 * - Dialog
 * - Dialog content
 * - Dialog header
 * - Dialog close
 * - Dialog body
 * - Dialog title
 * - Dialog description
 * - Dialog footer
 *
 */


/* OVERLAY
 -------------------- */

.dialog {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: var(--font);
  line-height: 1.5;
  color: var(--text);
  visibility: hidden;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.15s linear, visibility 0.15s;
}

/* DIALOG
 -------------------- */

.dialog-modal {
  position: relative;
  width: auto;
  margin: 1rem;
  pointer-events: none;
  transition: transform 0.3s ease-out;
  transform: translateY(50px);
}

/**
 * Open state
 */

.dialog-open {
  visibility: visible;
  opacity: 1;
}

.dialog-open .dialog-modal {
  transform: none;
}

/* DIALOG CONTENT
 -------------------- */

.dialog-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0 2rem;
  pointer-events: auto;
  background: var(--white);
  background-clip: padding-box;
  border-radius: 0.25rem;
  outline: 0;
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
}

@media (min-width: 576px) {

  .dialog-content {
    max-width: 464px;
  }
}

/* DIALOG HEADER
 -------------------- */

.dialog-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 3.5rem;
}

/* DIALOG CLOSE
 -------------------- */

.dialog-close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  appearance: none;
  fill: var(--icon);
}

.close-icon {
  display: block;
  width: 2rem;
}

.dialog-icon {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  font-size: 3rem;
}

/* DIALOG BODY
 -------------------- */

.dialog-body {
  padding: 0 1.5rem;
  text-align: center;
}

@media (min-width: 768px) {

  .dialog-body {
    padding: 0 3rem;
  }
}

/* DIALOG TITLE
 -------------------- */

.dialog-title {
  padding: 0;
  margin: 0 0 1rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.2;
}

/* DIALOG DESCRIPTION
 -------------------- */

.dialog-description {
  margin: 0 0 1.5rem;
  font-size: 0.875rem;
}

/* DIALOG FOOTER
 -------------------- */

.dialog-footer {
  display: flex;
  justify-content: center;
  padding: 0 1.5rem;
}

/**
 * Footer buttons
 */

.dialog-footer .btn {
  min-width: 130px;
  max-width: calc(50% - 0.5rem);
}
