/*
 * Pagination
 *
 * Index
 * - Pagination
 * - Page item
 * - List group item
 * - List group label
 * - List group button
 * - List multiple choice
 *
 */


/* PAGINATION
 -------------------- */

.pagination {
  display: flex;
  align-items: center;
  padding-left: 0;
  margin: 0;
  font-family: var(--font);
  font-size: 0.875rem;
  line-height: 1.5;
  color: var(--text);
  list-style: none;
}

/* PAGE ITEM
 -------------------- */

.page-item {
  margin: 0 0.125rem;
}

.page-item:first-child {
  margin: 0 0.25rem 0 0;
}

.page-item:last-child {
  margin: 0 0 0 0.25rem;
}

/* PAGE LINK
 -------------------- */

.page-link {
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.75rem;
  height: 2.75rem;
  padding: 0.5rem;
  color: var(--text);
  background: var(--white);
  border-radius: 0.25rem;
  outline: 0;
}

/**
 * Active state
 */

.active .page-link {
  color: var(--secondary-contrast);
  background: var(--secondary);
}

/**
 * Hover state
 */

@media (hover: hover) {

  .page-link:hover {
    color: var(--secondary-contrast);
    background: var(--secondary);
  }
}

/**
 * Focus on Tab
 */

.page-link.focus-visible {
  box-shadow: 0 0 0 0.125rem var(--white), 0 0 0 0.1875rem var(--secondary);
}

/* PAGE BUTTON
 -------------------- */

.page-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  color: var(--text);
  border-radius: 3rem;
  outline: none;
}

/**
 * Disabled state
 */

.page-btn.disabled {
  cursor: not-allowed;
  opacity: 0.28;
}

/**
 * Hover state
 */

@media (hover: hover) {

  .page-btn:not(.disabled):hover {
    color: var(--secondary-contrast);
    background: var(--secondary);
  }
}

/**
 * Focus on Tab
 */

.page-btn.focus-visible {
  color: var(--secondary-contrast);
  background: var(--secondary);
}

/* PAGE ICON
 -------------------- */

.page-icon {
  width: 2rem;
  height: 2rem;
  fill: currentColor;
}

.page-icon-prev {
  transform: rotate(180deg);
}
