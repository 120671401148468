/*
 * Button group
 *
 */


/* BUTTON GROUP
 -------------------- */

.btn-group {
  position: relative;
  display: inline-flex;
}

.btn-group .btn {
  position: relative;
  flex: 1 1 auto;
  margin: 0;
  font-weight: 400;
}

/**
 * Hover state
 */

.btn-group .btn:hover {
  z-index: 1;
}

/**
 * Rounded corners for first and last item
 */

.btn-group > .btn:not(:first-child),
.btn-group .btn-group-item:not(:first-child) .btn {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group > .btn:not(:last-child),
.btn-group > .btn-group-item:not(:last-child) .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
