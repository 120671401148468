/*
 * Spacing utitlities

 * Include all margin and padding individual properties
 * for 0, 8px, 16px 24px spacing
 *
 * Index
 * - Margin
 * - Padding
 *
 */


/* MARGIN
 -------------------- */

.m-0 {
  margin: 0;
}

.m-4 {
  margin: 0.25rem;
}

.m-8 {
  margin: 0.5rem;
}

.m-16 {
  margin: 1rem;
}

.m-24 {
  margin: 1.5rem;
}

.m-auto {
  margin: auto;
}

/**
 * Margin horizontal
 */

.mx-0 {
  margin-right: 0;
  margin-left: 0;
}

.mx-4 {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}

.mx-8 {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.mx-16 {
  margin-right: 1rem;
  margin-left: 1rem;
}

.mx-24 {
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

/**
 * Margin vertical
 */

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-4 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.my-8 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-16 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-24 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

/**
 * Margin top
 */

.mt-0 {
  margin-top: 0;
}

.mt-4 {
  margin-top: 0.25rem;
}

.mt-8 {
  margin-top: 0.5rem;
}

.mt-16 {
  margin-top: 1rem;
}

.mt-24 {
  margin-top: 1.5rem;
}

/**
 * Margin right
 */

.mr-0 {
  margin-right: 0;
}

.mr-4 {
  margin-right: 0.25rem;
}

.mr-8 {
  margin-right: 0.5rem;
}

.mr-16 {
  margin-right: 1rem;
}

.mr-24 {
  margin-right: 1.5rem;
}

/**
 * Margin bottom
 */

.mb-0 {
  margin-bottom: 0;
}

.mb-4 {
  margin-bottom: 0.25rem;
}

.mb-8 {
  margin-bottom: 0.5rem;
}

.mb-16 {
  margin-bottom: 1rem;
}

.mb-24 {
  margin-bottom: 1.5rem;
}

/**
 * Margin left
 */

.ml-0 {
  margin-left: 0;
}

.ml-4 {
  margin-left: 0.25rem;
}

.ml-8 {
  margin-left: 0.5rem;
}

.ml-16 {
  margin-left: 1rem;
}

.ml-24 {
  margin-left: 1.5rem;
}

/* PADDING
 -------------------- */

.p-0 {
  padding: 0;
}

.p-4 {
  padding: 0.25rem;
}

.p-8 {
  padding: 0.5rem;
}

.p-16 {
  padding: 1rem;
}

.p-24 {
  padding: 1.5rem;
}

/**
 * Padding horizontal
 */

.px-0 {
  padding-right: 0;
  padding-left: 0;
}

.px-4 {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

.px-8 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.px-16 {
  padding-right: 1rem;
  padding-left: 1rem;
}

.px-24 {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

/**
 * Padding vertical
 */

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-4 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-8 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-16 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-24 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

/**
 * Padding top
 */

.pt-0 {
  padding-top: 0;
}

.pt-4 {
  padding-top: 0.25rem;
}

.pt-8 {
  padding-top: 0.5rem;
}

.pt-16 {
  padding-top: 1rem;
}

.pt-24 {
  padding-top: 1.5rem;
}

/**
 * Padding right
 */

.pr-0 {
  padding-right: 0;
}

.pr-4 {
  padding-right: 0.25rem;
}

.pr-8 {
  padding-right: 0.5rem;
}

.pr-16 {
  padding-right: 1rem;
}

.pr-24 {
  padding-right: 1.5rem;
}

/**
 * Padding bottom
 */

.pb-0 {
  padding-bottom: 0;
}

.pb-4 {
  padding-bottom: 0.25rem;
}

.pb-8 {
  padding-bottom: 0.5rem;
}

.pb-16 {
  padding-bottom: 1rem;
}

.pb-24 {
  padding-bottom: 1.5rem;
}

/**
 * Padding left
 */

.pl-0 {
  padding-left: 0;
}

.pl-4 {
  padding-left: 0.25rem;
}

.pl-8 {
  padding-left: 0.5rem;
}

.pl-16 {
  padding-left: 1rem;
}

.pl-24 {
  padding-left: 1.5rem;
}
