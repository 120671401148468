/**
 * Base
 *
 * Specific reset and base styles for every elements
 *
 * Index
 * - Body base style
 * - Responsive media
 * - Iframe
 * - Links
 * - Webfonts
 */
/* stylelint-disable */


/**
 * set box-sizing for all elements
 */

*,
*::before,
*::after {
  box-sizing: border-box;
}

/**
 * Remove native arrow on type number
 */

input[type="number"] {
  appearance: textfield;
}

/* BODY BASE STYLE
 -------------------- */

body {
  font-family: var(--font);
  font-size: 0.875rem;
  line-height: 1.5;
  color: var(--text);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* RESPONSIVE MEDIA
 -------------------- */

img,
embed,
iframe,
object,
audio,
video {
  max-width: 100%;
  height: auto;
}

/* IFRAME
 -------------------- */

iframe {
  border: 0;
}

/* LINKS
 -------------------- */

a {
  text-decoration: none;
  cursor: pointer;
}

.link {
  color: var(--info);
  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.link:hover {
  color: var(--info-hover);
}

/* WEBFONTS
 -------------------- */

/* latin-ext */
@font-face {
  font-family: Muli;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Muli Regular"), local("Muli-Regular"), url("./fonts/Muli-Regular.woff2") format("woff2"), url("./fonts/Muli-Regular.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: Muli;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Muli Regular"), local("Muli-Regular"), url("./fonts/Muli-Regular.woff2") format("woff2"), url("./fonts/Muli-Regular.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: Muli;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Muli Bold"), local("Muli-Bold"), url("./fonts/Muli-Bold.woff2") format("woff2"), url("./fonts/Muli-Bold.woff") format("woff");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: Muli;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Muli Bold"), local("Muli-Bold"), url("./fonts/Muli-Bold.woff2") format("woff2"), url("./fonts/Muli-Bold.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
