/*
 * Border utilities
 *
 * Index
 * - Additive
 * - Subtractive
 *
 */


/* ADDITIVE
 -------------------- */

.border {
  border: 1px solid var(--border);
}

.border-left {
  border-left: 1px solid var(--border);
}

.border-top {
  border-top: 1px solid var(--border);
}

.border-right {
  border-right: 1px solid var(--border);
}

.border-bottom {
  border-bottom: 1px solid var(--border);
}

/* SUBTRACTIVE
 -------------------- */

/* stylelint-disable -- !important is needed for class how remove borders */

.border-0 {
  border: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

/* stylelint-enable */
