/*
 * Accordion
 *
 * For accessibility, we provide a style for focus only on Tab.
 * For enable this focus, load the javascript polyfill for focus-visible
 * (https://github.com/WICG/focus-visible).
 *
 * Index
 * - Accordion
 * - Accordion button
 * - Accordion content
 *
 */


/* ACCORDION
 -------------------- */

.accordion {
  --max-height: 800px;

  max-height: 4.25rem;
  overflow: hidden;
  font-family: var(--font, sans-serif);
  font-size: 0.875rem;
  line-height: 1.5;
  color: var(--text);
  background: var(--white);
  border: 1px solid var(--border);
  border-radius: 0.25rem;
  transition: max-height 0.3s ease-in-out;
}

/**
 * Open state
 */

.accordion[open] {
  max-height: var(--max-height);
}

.accordion[open] .accordion-arrow {
  transform: rotate(-90deg);
}

.accordion[open] .accordion-content {
  padding: 1rem 0.5rem 0;
  margin: 0 1rem 1rem;
}

/* ACCORDION BUTTON
 -------------------- */

.accordion-trigger {
  box-sizing: border-box;
  display: block;
  height: 4rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  outline: 0;
}

.accordion-trigger::-webkit-details-marker { /* Remove chrome native arrow */
  display: none;
}

/**
 * Focus on Tab
 */

.accordion-trigger.focus-visible {
  border-color: var(--secondary);
}

/**
 * Active state
 */

.accordion-trigger:active {
  background: var(--background-second);
}

/**
 * .accordion-header is an additionnal div
 * because Safari doesn't support flexbox on <summary> element
 */

.accordion-header {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0.5rem 1rem 1rem;
  cursor: pointer;
}

/**
 * Custom arrow
 */

.accordion-arrow {
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
  transform: rotate(90deg);
  fill: var(--icon);
}

/* ACCORDION CONTENT
 -------------------- */

.accordion-content {
  padding: 0 0.5rem;
  margin: 0 1rem;
  overflow: hidden;
  border-top: 1px solid var(--border);
}
