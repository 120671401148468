/*
 * Rating
 *
 * Index
 * - Stars
 * - Star
 * - Rating number
 *
 */

/* STARS
 -------------------- */

.rating .rating-stars {
  display: inline-flex;
  flex-shrink: 0;
}

/* STAR
 -------------------- */

.rating .rating-star {
  width: 1.25rem;
  height: 1.25rem;
  fill: var(--border);
}

/* RATING NUMBER
 -------------------- */

.rating .rating-number {
  margin-left: 0.5rem;
  font-size: 0.875rem;
  color: var(--text-second);
}
