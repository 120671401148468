/*
 * Alert
 *
 * Provide contextual feedback messages for typical user actions
 * with the handful of available and flexible alert messages.
 *
 * Default Alert color is Information colors
 *
 * Index
 * - Alert
 * - Alert icon
 * - Alert description
 * - Alert feedback
 *
 */


/* ALERT
 -------------------- */

.alert {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 0.5rem;
  font-family: var(--font);
  font-size: 0.875rem;
  line-height: 1.5;
  color: var(--info);
  background: var(--info-background);
  border: 1px solid var(--info);
  border-radius: 0.25rem;
  fill: var(--info);
}

/* ALERT ICON
 -------------------- */

.alert-icon {
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
}

/* ALERT DESCRIPTION
 -------------------- */

.alert-description {
  padding-top: 0.375rem;
  margin: 0;
}

/* ALERT FEEDBACK
 -------------------- */

/**
 * Alert success
 */

.alert-success {
  color: var(--success-text);
  background: var(--success-background);
  border-color: var(--success);
  fill: var(--success-text);
}

/**
 * Alert warning
 */

.alert-warning {
  color: var(--warning-text);
  background: var(--warning-background);
  border-color: var(--warning);
  fill: var(--warning);
}

/**
 * Alert danger
 */

.alert-danger {
  color: var(--danger-text);
  background: var(--danger-background);
  border-color: var(--danger);
  fill: var(--danger-text);
}
