/*
 * Input
 *
 * Index
 * - Input
 * - Textarea
 * - Label
 * - Sizes
 * - Error
 *
 */


.form-group {
  position: relative;
  display: block;
  margin-bottom: 1rem;
  font-family: var(--font);
  color: var(--text);
}

/* INPUT
 -------------------- */

.form-control {
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 3rem;
  padding: 0.75rem 1rem 0;
  margin: 0;
  font-size: 1rem;
  color: var(--text);
  background: var(--white);
  border: 1px solid var(--border-form);
  border-radius: 0.25rem;
  box-shadow: none;
  appearance: none;
}

/**
 * Fix position of chrome native icon on type date and time
 */

.form-control::-webkit-calendar-picker-indicator {
  margin-top: -0.75rem;
}

/**
 * Fix position of iOS date and time value
 */

.form-control::-webkit-date-and-time-value {
  padding-top: 0.625rem;
}

/**
 * Placeholder
 */

.form-control::placeholder {
  color: var(--text-second);
  opacity: 1;
}

/**
 * Focus state
 */

.form-control:focus ~ .form-label,
.form-control[required]:valid ~ .form-label,
.form-control.has-value ~ .form-label,
.form-control[type="date"] ~ .form-label,
.form-control[type="time"] ~ .form-label {
  transform: scale(0.625) translateY(-0.625rem);
}

.form-control:placeholder-shown ~ .form-label {
  transform: scale(0.625) translateY(-0.625rem);
}

/* stylelint-disable */

/* IE FIX */

.form-control:-ms-input-placeholder ~ .form-label {
  transform: scale(0.625) translateY(-0.625rem);
}
/* stylelint-enable */

.form-control:focus {
  border-color: var(--secondary);
  outline: 0;
}

/**
 * Disabled state
 */

.form-control:disabled,
.form-control[readonly] {
  color: var(--text-disabled);
  cursor: not-allowed;
  background: var(--background-second);
  opacity: 1;
}

/* TEXTAREA
 -------------------- */

.textarea-control {
  height: auto;
  padding-top: 1rem;
  line-height: 1.5;
}

/* LABEL
 -------------------- */

.form-label {
  position: absolute;
  top: 0.75rem;
  left: 1rem;
  display: block;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--text-second);
  pointer-events: none;
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transform-origin: 0 0;
}

/* SIZES
 -------------------- */

.form-control-md {
  height: 2.5rem;
}

.form-control-md + .form-label {
  top: 0.5rem;
}

.form-control-sm {
  height: 2rem;
}

.form-control-sm + .form-label {
  top: 0.25rem;
}

/* ERROR
 -------------------- */

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: var(--danger-text);
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: var(--danger);
}

.form-control.is-invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-feedback {
  display: block;
}
