/*
 * Text utilities
 *
 * Index
 * - Alignement
 * - Transformation
 * - Weight
 * - Style
 * - Truncate
 *
 */


/* ALIGNMENT
 -------------------- */

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

/* TRANSFORMATION
 -------------------- */

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

/* WEIGHT
 -------------------- */

.font-weight-normal {
  font-weight: 400;
}

.font-weight-bold {
  font-weight: 700;
}

/* STYLE
 -------------------- */

.font-italic {
  font-style: italic;
}

/* TRUNCATE
 -------------------- */

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
