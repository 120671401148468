/*
 * Modal
 *
 * Index
 * - Overlay
 * - Modal
 * - Modal content
 * - Modal header
 * - Modal actions
 * - Modal title
 * - Modal body
 *
 */


/* OVERLAY
 -------------------- */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: var(--font);
  visibility: hidden;
  background: rgba(0, 0, 0, 0.5);
  outline: 0;
  opacity: 0;
  transition: opacity 0.15s linear, visibility 0.15s;
}

/* MODAL
 -------------------- */

.modal-dialog {
  position: relative;
  width: 100%;
  pointer-events: none;
  transition: transform 0.3s ease-out;
  transform: translateY(50px);
}

@media (min-width: 768px) {

  .modal-dialog {
    max-width: 696px;
  }
}

/**
 * Open state
 */

.modal-open {
  visibility: visible;
  opacity: 1;
}

.modal-open .modal-dialog {
  transform: none;
}

/* MODAL CONTENT
 -------------------- */

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  pointer-events: auto;
  background: var(--white);
  background-clip: padding-box;
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
}

@media (min-width: 768px) {

  .modal-content {
    height: auto;
    border-radius: 0.25rem;
  }
}

/* MODAL HEADER
 -------------------- */

.modal-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--primary-contrast);
  background: var(--primary);
}

@media (min-width: 768px) {

  .modal-header {
    border-radius: 0.25rem 0.25rem 0 0;
  }
}

/**
 * Modal header inverse colors
 */

.modal-header-inverse {
  color: var(--text);
  background: none;
}

.modal-header-inverse .modal-action,
.modal-header-inverse .modal-close {
  fill: var(--icon);
}

/* MODAL ACTIONS
 -------------------- */

.modal-action,
.modal-close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  padding: 0;
  font-weight: 700;
  color: var(--white);
  cursor: pointer;
  background: transparent;
  border: 0;
  appearance: none;
  fill: currentColor;
}

.modal-action ~ .modal-title,
.modal-close ~ .modal-title {
  padding-left: 0;
}

.modal-action-left,
.modal-close-left {
  margin-right: auto;
}

/**
 * Modal header button icons
 */

.action-icon,
.close-icon {
  display: block;
  width: 2rem;
}

/* MODAL TITLE
 -------------------- */

.modal-title {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  padding-left: 3.5rem;
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
}

/* MODAL BODY
 -------------------- */

.modal-body {
  padding: 2.5rem 0.5rem;
  overflow-y: auto;
}

.modal-header-inverse + .modal-body {
  padding-top: 0;
}

@media (min-width: 768px) {

  .modal-body {
    max-height: 86vh;
    padding-right: 5rem;
    padding-left: 5rem;
  }
}
