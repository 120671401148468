/*
 * Select
 *
 * Index
 * - Select
 * - Select label
 * - Select error
 *
 */


.form-group {
  position: relative;
  display: block;
  margin-bottom: 1rem;
  font-family: var(--font);
  color: var(--text);
}

/* SELECT
 -------------------- */

.form-select {
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 3rem;
  padding: 0.75rem 3rem 0 1rem;
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--text);
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NCA2NCIgZmlsbD0icmdiYSgyMywgMzAsIDUyLCAwLjcyKSI+PHBhdGggZD0iTTIzIDI2bDkgMTIgOS0xMnoiLz48L3N2Zz4=") no-repeat right 0.5rem center/2rem 2rem;
  background-color: var(--white);
  border: 1px solid var(--border-form);
  border-radius: 0.25rem;
  box-shadow: none;
  appearance: none;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000000;
}

.form-select::-ms-expand { /* IE fix remove native arrow */
  display: none;
}

/**
 * Placeholder
 */

.form-select::placeholder {
  color: var(--text-second);
}

/**
 * Focus state
 */

.form-select:focus ~ .form-select-label,
.form-select[required]:valid ~ .form-select-label,
.form-select.has-value ~ .form-select-label {
  transform: scale(0.625) translateY(-0.625rem);
}

.form-select:focus {
  border-color: var(--secondary);
  outline: 0;
}

/**
 * Disabled state
 */

.form-select:disabled,
.form-select[readonly] {
  color: var(--text-disabled);
  cursor: not-allowed;
  background-color: var(--background-second);
}

/* SELECT LABEL
 -------------------- */

.form-select-label {
  position: absolute;
  top: 0.75rem;
  left: 1rem;
  display: block;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--text-second);
  pointer-events: none;
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transform-origin: 0 0;
}

/* SELECT ERROR
 -------------------- */

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: var(--danger-text);
}

.form-select.is-invalid,
.was-validated .form-select:invalid {
  border-color: var(--danger);
}

.form-select.is-invalid ~ .invalid-feedback,
.was-validated .form-select:invalid ~ .invalid-feedback {
  display: block;
}
