/*
 * Drawer
 *
 * Index
 * - Overlay
 * - Drawer
 * - Drawer position
 * - Drawer content
 * - Drawer header
 * - Drawer actions
 * - Drawer title
 * - Drawer body
 * - Drawer open state
 *
 */


/* OVERLAY
 -------------------- */

.drawer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: var(--font);
  visibility: hidden;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.15s linear, visibility 0.15s;
}

/* DRAWER
 -------------------- */

.drawer-dialog {
  position: relative;
  width: 100%;
  pointer-events: none;
  transition: transform 0.3s ease-out;
  transform: translateX(-100%);
}

/* DRAWER POSITION
 -------------------- */

.drawer-right {
  justify-content: flex-end;
}

.drawer-right .drawer-dialog {
  transform: translateX(100%);
}

/**
 * Bottom position
 */

.drawer-bottom {
  align-items: flex-end;
}

.drawer-bottom .drawer-dialog {
  width: 100%;
  transform: translateY(100%);
}

.drawer-bottom .drawer-content {
  height: auto;
  min-height: 6.25rem;
}

/* DRAWER CONTENT
 -------------------- */

.drawer-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  pointer-events: auto;
  background-color: var(--white);
  background-clip: padding-box;
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
}

/* DRAWER HEADER
 -------------------- */

.drawer-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  height: 3.5rem;
  background: var(--white);
}

/**
 * Delimiter when needed
 */

.drawer-header-delimiter {
  border-bottom: 1px solid var(--border);
}

/**
 * Header inverse colors
 */

.drawer-header-inverse {
  margin-bottom: 2.5rem;
  color: var(--primary-contrast);
  background: var(--primary);
}

.drawer-header-inverse .action-icon,
.drawer-header-inverse .close-icon {
  fill: var(--white);
}

/* DRAWER ACTIONS
 -------------------- */

.drawer-action,
.drawer-close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  appearance: none;
  fill: var(--icon);
}

.drawer-action ~ .drawer-title,
.drawer-close ~ .drawer-title {
  padding-left: 0;
}

.drawer-action-left,
.drawer-close-left {
  margin-right: auto;
}

/**
 * Header button icons
 */

.action-icon,
.close-icon {
  display: block;
  width: 2rem;
}

.action-icon {
  transform: rotate(180deg);
}

/* DRAWER TITLE
 -------------------- */

.drawer-title {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  padding-left: 3.5rem;
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
}

/* DRAWER BODY
 -------------------- */

.drawer-body {
  height: calc(100vh - 3.5rem);
  overflow-y: auto;
}

.drawer-body + .drawer-header {
  border-top: 1px solid var(--border);
}

.drawer-inner {
  padding: 0 1rem 2.5rem;
}

@media (min-width: 768px) {

  .drawer-inner {
    padding: 0 2rem 2.5rem;
  }
}

/* DRAWER OPEN STATE
 -------------------- */

.drawer-open {
  visibility: visible;
  opacity: 1;
}

.drawer-open .drawer-dialog {
  transform: none;
}
