/*
 * Plate Number
 *
 * Index
 * - Custom font
 * - Plate Number
 * - Input
 *
 */

/* PLATE NUMBER
 -------------------- */

.plate-number-start,
.plate-number-end {
  width: 1.75rem;
  height: 3rem;
  background: var(--info-text);
}

.plate-number-start {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-right: 0;
  color: var(--info-contrast);
  border-radius: 0.25rem 0 0 0.25rem;
}

.plate-number-end {
  border-radius: 0 0.25rem 0.25rem 0;
}

.plate-number-icon {
  width: 1.25rem;
  height: 1.25rem;
  fill: #f3db53;
}

.plate-number-location {
  font-size: 0.875rem;
  font-weight: 700;
}

/* INPUT
 -------------------- */

.plate-number-input {
  position: relative;
  display: block;
  flex: 1 1 auto;
  width: 1%;
  height: 3rem;
  margin: 0;
  font-family: Oswald, sans-serif;
  font-size: 2rem;
  color: var(--text);
  text-align: center;
  background: var(--white);
  border: 1px solid var(--border-form);
  border-radius: 0;
  box-shadow: none;
  appearance: none;
}

.plate-number-input::placeholder {
  color: var(--border);
  opacity: 1;
}

.plate-number-input:focus {
  border-color: var(--secondary);
  outline: 0;
}

/* PLATE NUMBER
 -------------------- */

.motorbike-plate .plate-number-input {
  height: 6rem;
  resize: none;
  border-left: 0;
}

.motorbike-plate > .input-group-prepend > .input-group-text {
  margin: 0;
}

.motorbike-plate .input-group-text {
  align-items: flex-start;
  padding: 0;
}

.motorbike-plate .plate-number-start {
  border-radius: 3px 0 0;
}

.motorbike-plate .plate-number-end {
  border-radius: 0 3px 0 0;
}
