/*
 * Collapse
 *
 * Index
 * - Collapse
 * - Content
 * - Button
 *
 */


/* COLLAPSE
 -------------------- */

.collapse {
  --max-height: 500px;
}

/* CONTENT
 -------------------- */

.collapsed-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out;
}

/**
 * Open state
 */

.collapse-open {
  max-height: var(--max-height);
}

/* BUTTON
 -------------------- */

.collapse-btn {
  padding-right: 1rem;
  padding-left: 1rem;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.collapse-btn-centered {
  display: flex;
  justify-content: center;
}
