/**
 * Variables
 *
 * List of all reusable variables (colors, breakpoints...)
 * using CSS Custom properties (https://developer.mozilla.org/en-US/docs/Web/CSS/--*)
 *
 */

:root {
  /* THEME
   -------------------- */

  /**
   * Primary
   */
  --primary: #002b6f;
  --primary-hover: #00173c;
  --primary-darken: #00173c;
  --primary-lighten: #ebeef4;
  --primary-contrast: #ffffff;

  /**
   * Secondary
   */
  --secondary: #0071dc;
  --secondary-hover: #0365c5;
  --secondary-darken: #0365c5;
  --secondary-lighten: #ebf6ff;
  --secondary-contrast: #ffffff;

  /**
   * Accent
   */
  --accent: #fcb731;
  --accent-hover: #eca000;
  --accent-darken: #eca000;
  --accent-lighten: #fef8e2;
  --accent-contrast: #7a5400;

  /* FEEDBACK
   -------------------- */

  /**
   * Information
   */
  --info: #0071dc;
  --info-hover: #0365c5;
  --info-text: #0365c5;
  --info-background: #ebf6ff;
  --info-contrast: #ffffff;

  /**
   * Success
   */
  --success: #00bc0d;
  --success-hover: #00784c;
  --success-text: #00ab6d;
  --success-background: #ebffec;
  --success-contrast: #ffffff;

  /**
   * Warning
   */
  --warning: #fcb731;
  --warning-hover: #eca000;
  --warning-text: #c98800;
  --warning-background: #fef8e2;
  --warning-contrast: #7a5400;

  /**
   * Danger
   */
  --danger: #ff403b;
  --danger-hover: #b70000;
  --danger-text: #ea0000;
  --danger-background: #ffeceb;
  --danger-contrast: #ffffff;

  /* MONOCHROME
   -------------------- */
  --icon: rgba(23, 30, 52, 0.72);
  --text: rgba(23, 30, 52, 0.88);
  --text-second: rgba(67, 66, 100, 0.64);
  --text-disabled: rgba(62, 64, 124, 0.4);
  --border-form: rgba(62, 64, 124, 0.32);
  --border: rgba(62, 64, 124, 0.16);
  --background: #edeef7;
  --background-second: #f4f5fa;
  --white: #ffffff;

  /* FONT
   -------------------- */
  --font: muli, arial, sans-serif;
}

/* AUTO5
  -------------------- */

.auto5-theme {
  /**
   * Primary
   */
  --primary: #002b6f;
  --primary-hover: #00173c;
  --primary-darken: #00173c;
  --primary-lighten: #ebeef4;
  --primary-contrast: #ffffff;

  /**
   * Secondary
   */
  --secondary: #0071dc;
  --secondary-hover: #0365c5;
  --secondary-darken: #0365c5;
  --secondary-lighten: #ebf6ff;
  --secondary-contrast: #ffffff;

  /**
   * Accent
   */
  --accent: #e00008;
  --accent-hover: #c90007;
  --accent-darken: #c90007;
  --accent-lighten: #fee2e3;
  --accent-contrast: #ffffff;
}

/* ATU
  -------------------- */

.atu-theme {
  /**
   * Primary
   */
  --primary: #3c3c3b;
  --primary-hover: #242424;
  --primary-darken: #242424;
  --primary-lighten: #ebebeb;
  --primary-contrast: #ffffff;

  /**
   * Secondary
   */
  --secondary: #bb1e10;
  --secondary-hover: #a81b0e;
  --secondary-darken: #a81b0e;
  --secondary-lighten: #f5d9dc;
  --secondary-contrast: #ffffff;

  /**
   * Accent
   */
  --accent: #bb1e10;
  --accent-hover: #a81b0e;
  --accent-darken: #a81b0e;
  --accent-lighten: #f5d9dc;
  --accent-contrast: #ffffff;
}

/* Midas
  -------------------- */

.midas-theme {
  /**
   * Primary
   */
  --primary: #2f2f2f;
  --primary-hover: #000000;
  --primary-darken: #000000;
  --primary-lighten: #9b9b9b;
  --primary-contrast: #ffffff;

  /**
   * Secondary
   */
  --secondary: #ffd300;
  --secondary-hover: #fcc326;
  --secondary-darken: #000000;
  --secondary-lighten: #fffae5;
  --secondary-contrast: #000000;

  /**
   * Accent
   */
  --accent: #ffd300;
  --accent-hover: #fcc326;
  --accent-darken: #000000;
  --accent-lighten: #fffae5;
  --accent-contrast: #000000;
}
