/*
 * Carousel
 *
 * Index
 * - Container
 * - Bullets
 * - Arrows
 * - Slide
 * - Image
 *
 */

/* CONTAINER
 -------------------- */

.carousel.swiper-container {
  position: relative;
  z-index: 1;
  padding: 0;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
  list-style: none;
}

.carousel .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.carousel .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 0.125rem;
}

.carousel.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0.5rem;
}

/* BULLETS
 -------------------- */

.carousel .swiper-pagination-bullet {
  width: 1.5rem;
  height: 0.1875rem;
  background: rgba(0, 0, 0, 0.24);
  border-radius: 0;
  opacity: 1;
}

.carousel .swiper-pagination-bullet-active {
  background: rgba(0, 0, 0, 0.64);
}

.carousel .swiper-pagination-progressbar {
  background: var(--border);
}

.carousel .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: var(--text);
}

.carousel .swiper-scrollbar {
  background: var(--border);
}

.carousel .swiper-scrollbar-drag {
  background: var(--text);
}

/* ARROWS
 -------------------- */

.carousel .swiper-button-prev,
.carousel .swiper-button-next {
  display: none;
}

@media (min-width: 768px) {

  .carousel .swiper-button-prev,
  .carousel .swiper-button-next {
    display: flex;
    width: 3rem;
    height: 3rem;
    background: rgba(0, 0, 0, 0.64);
    border-radius: 3rem;
  }

  .carousel .swiper-button-prev {
    transform: rotate(180deg);
  }

  .carousel .swiper-button-prev .swiper-icon,
  .carousel .swiper-button-next .swiper-icon {
    width: 2rem;
    height: 2rem;
    fill: var(--white);
  }
}

.swiper-button-prev::after,
.swiper-button-next::after {
  display: none;
}

/**
 * Hover state
 */

@media (hover: hover) {

  .carousel .swiper-button-prev:hover,
  .carousel .swiper-button-next:hover {
    background: var(--white);
  }

  .carousel .swiper-button-prev:hover .swiper-icon,
  .carousel .swiper-button-next:hover .swiper-icon {
    fill: var(--text);
  }
}

.carousel .swiper-button-prev,
.carousel .swiper-container-rtl .swiper-button-next {
  left: 1rem;
}

.carousel .swiper-button-next,
.carousel .swiper-container-rtl .swiper-button-prev {
  right: 1rem;
}

@media (min-width: 768px) {

  .carousel .swiper-button-prev,
  .carousel .swiper-container-rtl .swiper-button-next {
    left: 1.5rem;
  }

  .carousel .swiper-button-next,
  .carousel .swiper-container-rtl .swiper-button-prev {
    right: 1.5rem;
  }
}

/* SLIDE
 -------------------- */

.carousel .slide-zoom {
  display: block;
  width: 100%;
  text-align: center;
}

.carousel .swiper-slide {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 18px;
  text-align: center;
}

/* IMAGE
 -------------------- */

.carousel .swiper-slide img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  pointer-events: none;
}
