/*
 * Buttons
 *
 * Use custom button styles for actions in forms, dialogs, and more
 * with support for multiple sizes, states, and more.
 *
 * Index
 * - Button
 * - Plain buttons
 * - Outline buttons
 * - Disabled buttons
 * - Button sizes
 *
 */


/* BUTTON
 -------------------- */

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  padding: 0 1rem;
  margin-bottom: 1rem;
  font-family: var(--font);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375;
  color: var(--text);
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  background: var(--background-second);
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: background 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.btn:not(:disabled) {
  cursor: pointer;
}

/**
 * Fix states
 */

.btn:hover {
  text-decoration: none;
}

.btn:focus {
  outline: 0;
}

/* PLAIN BUTTONS
 -------------------- */

/**
 * Button primary
 */

.btn-primary {
  color: var(--primary-contrast);
  background: var(--primary);
}

.btn-primary:not(:disabled):hover,
.btn-primary:not(:disabled):focus {
  background: var(--primary-hover);
}

/**
 * Button secondary
 */

.btn-secondary {
  color: var(--secondary-contrast);
  background: var(--secondary);
}

.btn-secondary:not(:disabled):hover,
.btn-secondary:not(:disabled):focus {
  background: var(--secondary-hover);
}

/**
 * Button success
 */

.btn-success {
  color: var(--success-contrast);
  background: var(--success-text);
}

.btn-success:not(:disabled):hover,
.btn-success:not(:disabled):focus {
  background: var(--success-hover);
}

/**
 * Button warning
 */

.btn-warning {
  color: var(--warning-contrast);
  background: var(--warning);
}

.btn-warning:not(:disabled):hover,
.btn-warning:not(:disabled):focus {
  background: var(--warning-hover);
}

/**
 * Button danger
 */

.btn-danger {
  color: var(--danger-contrast);
  background: var(--danger-text);
}

.btn-danger:not(:disabled):hover,
.btn-danger:not(:disabled):focus {
  background: var(--danger-hover);
}

/**
 * Button default
 */

.btn-default:not(:disabled):hover,
.btn-default:not(:disabled):focus {
  background: var(--background);
}

/**
 * Button link
 */

.btn-link {
  font-weight: 400;
  color: var(--secondary);
  background: transparent;
}

.btn-link:not(:disabled):hover,
.btn-link:not(:disabled):focus {
  color: var(--secondary-hover);
}

/* OUTLINE BUTTONS
 -------------------- */

.btn-outline-primary,
.btn-outline-secondary,
.btn-outline-success,
.btn-outline-warning,
.btn-outline-danger,
.btn-outline-default {
  background: transparent;
}

/**
 * Button outline default
 */

.btn-outline-default {
  color: var(--text-second);
  border-color: var(--text-second);
}

.btn-outline-default:hover,
.btn-outline-default:focus {
  background: var(--background);
}

/**
 * Button outline primary
 */

.btn-outline-primary {
  color: var(--primary);
  border-color: var(--primary);
}

.btn-outline-primary:hover,
.btn-outline-primary:focus {
  background: var(--primary-lighten);
}

/**
 * Button outline secondary
 */

.btn-outline-secondary {
  color: var(--secondary);
  border-color: var(--secondary);
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus {
  background: var(--secondary-lighten);
}

/**
 * Button outline success
 */

.btn-outline-success {
  color: var(--success-text);
  border-color: var(--success-text);
}

.btn-outline-success:hover,
.btn-outline-success:focus {
  background: var(--success-background);
}

/**
 * Button outline warning
 */

.btn-outline-warning {
  color: var(--warning-text);
  border-color: var(--warning-text);
}

.btn-outline-warning:hover,
.btn-outline-warning:focus {
  background: var(--warning-background);
}

/**
 * Button outline danger
 */

.btn-outline-danger {
  color: var(--danger-text);
  border-color: var(--danger-text);
}

.btn-outline-danger:hover,
.btn-outline-danger:focus {
  background: var(--danger-background);
}

/* DISABLED BUTTONS
 -------------------- */

.btn:disabled {
  cursor: not-allowed;
  opacity: 0.16;
}

[class*="btn-outline"]:disabled:hover,
[class*="btn-outline"]:disabled:focus {
  background: var(--white);
}

/* BUTTON SIZES
 -------------------- */

.btn-icon {
  width: 2rem;
  margin-right: 0.5rem;
  fill: currentColor;
}

.btn-link .btn-icon {
  transition: fill 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  fill: currentColor;
}

/**
 * Medium button
 */

.btn-md,
.btn-group-md > .btn {
  height: 2.5rem;
  padding: 0 1rem;
  font-size: 0.875rem;
}

.btn-md .btn-icon {
  width: 1.5rem;
  height: 1.5rem;
}

/**
 * Small button
 */

.btn-sm,
.btn-group-sm > .btn {
  height: 2rem;
  padding: 0 1rem;
  font-size: 0.75rem;
  line-height: 1.5;
}

.btn-sm .btn-icon {
  width: 1.25rem;
  height: 1.25rem;
}

/**
 * Button block
 */

.btn-block {
  width: 100%;
}
