/*
 * Spinner
 *
 * Index
 * - Spinner
 * - Spinner circle
 * - Spinner animation
 *
 */


/* SPINNER
 -------------------- */

.spinner {
  width: 3rem;
  height: 3rem;
  transform-origin: center;
  animation: road-rotate 2s linear infinite;
}

/* SPINNER CIRCLE
 -------------------- */

.spinner-circle {
  animation: road-dash 1.5s ease-in-out infinite;
  fill: none;
  stroke: var(--secondary);
  stroke-width: 3;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
}

/* SPINNER ANIMATION
 -------------------- */

@keyframes road-rotate {

  100% {
    transform: rotate(360deg);
  }
}

@keyframes road-dash {

  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}
