/*
 * Stepper
 *
 * Index
 * - Stepper
 * - Stepper item
 * - Stepper link
 * - Stepper icon
 * - Stepper title
 * - Step completed
 *
 */


/* STEPPER
 -------------------- */

.stepper {
  display: flex;
  padding-left: 0;
  margin: 0;
  font-family: var(--font);
  line-height: 1.5;
  list-style: none;
}

/* STEPPER ITEM
 -------------------- */

.stepper-item:not(:last-child) {
  display: flex;
  flex: auto;
  align-items: center;
}

/**
 * Line between items
 */


.stepper-item:not(:last-child)::after {
  flex: 1;
  height: 2px;
  margin: 0 0 1.5rem;
  content: "";
  background: var(--border);
}

/**
 * Disabled click for current and next steps
 */


.stepper-item:not(.completed) .stepper-link {
  cursor: not-allowed;
}

/* STEPPER LINK
 -------------------- */

.stepper-link {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* STEPPER ICON
 -------------------- */

.stepper-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  font-size: 1rem;
  color: var(--text-second);
  background: var(--border);
  border-radius: 50%;
  fill: currentColor;
}

/* STEPPER TITLE
 -------------------- */

.stepper-title {
  display: block;
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: var(--text-disabled);
  text-transform: uppercase;
}

/* STEP COMPLETED
 -------------------- */

.completed:not(:last-child)::after {
  background: var(--secondary);
}

.completed .stepper-icon,
.current .stepper-icon {
  color: var(--secondary-contrast);
  background: var(--secondary);
}

.current .stepper-title {
  font-weight: 700;
  color: var(--text);
}
