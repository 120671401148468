/*
 * Tooltip
 *
 * Fit Tooltip for short text only.
 *
 * Index
 * - Tooltip
 * - Tooltip position
 *
 */


/* TOOLTIP
 -------------------- */

[tooltip] {
  position: relative;
}

[tooltip]::after {
  position: absolute;
  top: -0.5rem;
  left: 50%;
  z-index: 1;
  padding: 0.5rem;
  font-family: var(--font);
  font-size: 0.75rem;
  color: var(--text);
  text-align: center;
  white-space: nowrap;
  pointer-events: none;
  content: attr(tooltip);
  background: var(--white);
  border: 1px solid var(--border);
  border-radius: 0.25rem;
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
  opacity: 0;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  transform: translateX(-50%) translateY(-100%);
}

[tooltip]:hover::after,
[tooltip]:focus-within::after {
  opacity: 1;
}

/* TOOLTIP POSITION
 -------------------- */

/**
 * Top position
 */

[tooltip-position="top"]::after {
  left: 50%;
  transform: translateX(-50%) translateY(-70%);
}

[tooltip-position="top"]:hover::after {
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
}

/**
 * Left position
 */

[tooltip-position="left"]::after {
  top: 50%;
  left: 0%;
  margin-left: -0.5rem;
  transform: translateX(-94%) translateY(-55%);
}

[tooltip-position="left"]:hover::after {
  transform: translateX(-100%) translateY(-55%);
}

/**
 * Bottom position
 */

[tooltip-position="bottom"]::after {
  top: 100%;
  left: 50%;
  margin-top: 0.375rem;
  transform: translateX(-50%) translateY(-40%);
}

[tooltip-position="bottom"]:hover::after {
  transform: translateX(-50%) translateY(0);
}

/**
 * Right position
 */

[tooltip-position="right"]::after {
  top: 50%;
  left: 100%;
  margin-left: 0.5rem;
  transform: translateX(-14%) translateY(-55%);
}

[tooltip-position="right"]:hover::after {
  transform: translateX(0) translateY(-55%);
}
